import axios from 'axios'
import storage from 'src/services/storage'
import { store } from 'src/store'
import router from 'src/router'
import { getCurrentLocale } from 'src/helpers/functions'

const BASE_URL = process.env.VUE_APP_API_ORGADMIN;
const language = getCurrentLocale();
const api = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': language
  }
})

api.interceptors.request.use(function (config) {
  const token = storage.get('token')

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export function handleError(error) {
  if (error.response.status === 401) {
    store.dispatch('user/logout')
  }
  return error.response
}

api.getOrFail = async function (url, payload) {
  return api.get(url, payload).catch(error => {
    if (error.response.status === 404) {
      router.push({ name: 'Home' })
      return false
    }
    throw error
  })
}

export async function listUserApi(payload) {
  const url = `${BASE_URL}/users`
  return await api.get(url, payload).catch(handleError)
}

export async function inviteUser(payload) {
  const url = `${BASE_URL}/users/invite`
  return await api.post(url, payload).catch(handleError)
}

export async function getUser(id) {
  const url = `${BASE_URL}/users/${id}`
  return await api.getOrFail(url).catch(handleError)
}

export async function updateUserApi(id, payload) {
  const url = `${BASE_URL}/users/${id}`
  return await api.put(url, payload).catch(handleError)
}

export async function getCountriesApi() {
  const url = `${BASE_URL}/countries`
  return await api.get(url).catch(handleError)
}

export async function getIndustriesApi() {
  const url = `${BASE_URL}/industries`
  return await api.get(url).catch(handleError)
}

export async function getEmployeeSizesApi() {
  const url = `${BASE_URL}/employee-sizes`
  return await api.get(url).catch(handleError)
}

export async function verifyEmailApi(payload) {
  const url = `${BASE_URL}/email/verify`
  return await api.post(url, payload).catch(handleError)
}

export async function sendCodeEmailApi(payload) {
  const url = `${BASE_URL}/code/email/send`
  return await api.post(url, payload).catch(handleError)
}

export async function verifyCodeEmailApi(payload) {
  const url = `${BASE_URL}/code/email/verify`
  return await api.post(url, payload).catch(handleError)
}

export async function registerCompanyApi(payload) {
  const url = `${BASE_URL}/organization/register`
  return await api.post(url, payload).catch(handleError)
}

export async function deleteUser(id) {
  const url = `${BASE_URL}/users/${id}`
  return await api.delete(url).catch(handleError)
}

export async function resetPasswordApi(id) {
  const url = `${BASE_URL}/users/${id}/password/reset`
  return await api.put(url).catch(handleError)
}

export async function updateStatusUserApi(id, status) {
  const url = `${BASE_URL}/users/${id}/status/update`
  return await api.put(url, status).catch(handleError)
}

export async function getUserLoggedApi() {
  const url = `${BASE_URL}/user/logged`
  return await api.get(url).catch(handleError)
}

export async function loginAsOrganizationAdminApi() {
  const url = `${BASE_URL}/organization/verify_login`;
  return await api.get(url).catch(handleError)
}

export async function listConversationApi(payload) {
  const url = `${BASE_URL}/conversations`
  return await api.get(url, payload).catch(handleError)
}

export async function createConversationApi(payload) {
  const url = `${BASE_URL}/conversations`
  return await api.post(url, payload).catch(handleError)
}

export async function updateConversationApi(payload) {
  const url = `${BASE_URL}/conversations/${payload._id}`
  return await api.put(url, payload).catch(handleError)
}

export async function getConversationApi(id) {
  const url = `${BASE_URL}/conversations/${id}`
  return await api.getOrFail(url).catch(handleError)
}

export async function deleteConversationApi(id) {
  const url = `${BASE_URL}/conversations/${id}`
  return await api.delete(url).catch(handleError)
}

export async function exportConversationApi(id) {
  const url = `${BASE_URL}/export/conversations/${id}`
  return await api.get(url).catch(handleError)
}

export async function checkAdminConversationApi(id) {
  const url = `${BASE_URL}/admins/conversations/${id}`
  return await api.get(url).catch(handleError)
}

export async function getListUserOptionApi(params = {}) {
  const url = `${BASE_URL}/options/users`
  return await api.get(url, { params }).catch(handleError)
}

export async function getListUserGroupApi(payload) {
  const url = `${BASE_URL}/users/list/group`
  return await api.get(url, payload).catch(handleError)
}

export async function countStatusConversationApi() {
  const url = `${BASE_URL}/count/conversation`
  return await api.get(url).catch(handleError)
}

export async function filePresigned(payload) {
  const url = `${BASE_URL}/org/presigned`
  return await api.post(url, payload).catch(handleError)
}

export async function updateOrganizationApi(payload) {
  const url = `${BASE_URL}/organization`
  return await api.put(url, payload).catch(handleError)
}

export async function getAllCompanyUserExceptAdminApi(params = {}) {
  const url = `${BASE_URL}/organization/users-except-admin`
  return await api.get(url, { params }).catch(handleError)
}

export async function getAllCompanyUserApi(params = {}) {
  const url = `${BASE_URL}/organization/users`
  return await api.get(url, { params }).catch(handleError)
}

export async function transferOwnerApi(payload) {
  const url = `${BASE_URL}/owner/transfer`
  return await api.post(url, payload).catch(handleError)
}

export async function getListStoragesApi() {
  const url = `${BASE_URL}/storages`
  return await api.get(url).catch(handleError)
}

export async function getCurrentStoragesApi(id) {
  const url = `${BASE_URL}/storages/current`
  return await api.get(url).catch(handleError)
}

export async function getStoragesApi(id) {
  const url = `${BASE_URL}/storages/${id}`
  return await api.get(url).catch(handleError)
}

export async function getStoragesUpgradeDetailApi(id) {
  const url = `${BASE_URL}/storages/${id}/payment/detail`
  return await api.get(url).catch(handleError)
}

export async function upgradeStorageApi(payload) {
  const url = `${BASE_URL}/payment/storage/change`
  return await api.post(url, payload).catch(handleError)
}

export async function getListContactUserApi(id, payload) {
  const url = `${BASE_URL}/users/${id}/contact`
  return await api.getOrFail(url, payload).catch(handleError)
}

export async function exportListContactUserApi(id) {
  const url = `${BASE_URL}/users/${id}/contact/export`
  return await api.get(url).catch(handleError)
}

export async function updateSecurity(payload) {
  const url = `${BASE_URL}/security/update`
  return await api.put(url, payload).catch(handleError)
}

export async function validateIpApi(payload) {
  const url = `${BASE_URL}/validate-ip`
  return await api.post(url, payload).catch(handleError)
}

export async function updateLimitFileApi(payload) {
  const url = `${BASE_URL}/user/limit-file`
  return await api.put(url, payload).catch(handleError)
}

export async function updateLimitIpApi(payload) {
  const url = `${BASE_URL}/user/limit-ip`
  return await api.put(url, payload).catch(handleError)
}

export async function listUserWithSecurityApi(payload) {
  const url = `${BASE_URL}/security/users`
  return await api.get(url, payload).catch(handleError)
}

export async function listEndpointApi(payload) {
  const url = `${BASE_URL}/endpoints`
  return await api.get(url, payload).catch(handleError)
}

export async function deleteEndpointApi(id) {
  const url = `${BASE_URL}/endpoints/${id}`
  return await api.delete(url).catch(handleError)
}

export async function updateDeviceApi(id) {
  const url = `${BASE_URL}/endpoints/${id}`
  return await api.put(url).catch(handleError)
}

export async function cancelRequestApi(id) {
  const url = `${BASE_URL}/endpoints/${id}/cancel`
  return await api.put(url).catch(handleError)
}

export async function getCurrentPlanDetail() {
  const url = `${BASE_URL}/plans/current`
  return await api.get(url).catch(handleError)
}

export async function getCurrentTimeTrial(id) {
  const url = `${BASE_URL}/trials/${id}`
  return await api.get(url).catch(handleError)
}

export async function getPlanList() {
  const url = `${BASE_URL}/plans`
  return await api.get(url).catch(handleError)
}

export async function getPlanToUpgrade(id) {
  const url = `${BASE_URL}/plans/${id}`
  return await api.get(url).catch(handleError)
}

export async function getListCard(limit = null, after = null, before = null) {
  const url = `${BASE_URL}/payment/cards`
  return await api.get(url, {
    params: {
      limit, after, before
    }
  }).catch(handleError)
}

export async function getSecretClientApi(payload) {
  const url = `${BASE_URL}/payment/secret-key`
  return await api.get(url, payload).catch(handleError)
}

export async function getSecretUserApi(payload) {
  const url = `${BASE_URL}/payment/secret-key-user`
  return await api.get(url, payload).catch(handleError)
}

export async function getStripeApi(id) {
  const url = `${BASE_URL}/stripe/customer/${id}`
  return await api.get(url).catch(handleError)
}

export async function getPlanUpgradeDetail(id, period, licenses = null) {
  const url = `${BASE_URL}/payment/plan/detail/${id}/${period}` + (licenses === null ? '' : `/${licenses}`)
  return await api.get(url).catch(handleError)
}

export async function switchPlanToYearly() {
  const url = `${BASE_URL}/payment/plan/switch-yearly`
  return await api.post(url).catch(handleError)
}

export async function upgradePlan(id, period, card, licenses, cvc, error_number) {
  const url = `${BASE_URL}/payment/plan/change`
  return await api.post(url, {
    id, period, card, licenses, cvc, error_number
  }).catch(handleError)
}

export async function getOptionStopServiceApi() {
  const url = `${BASE_URL}/plans/options/stop-service`
  return await api.get(url).catch(handleError)
}

export async function requestStopServiceApi(payload) {
  const url = `${BASE_URL}/plans/request/stop-service`
  return await api.post(url, payload).catch(handleError)
}

export async function setPaymentMethodDefaultApi(id) {
  const url = `${BASE_URL}/payment/payment-method/${id}/default`
  return await api.post(url).catch(handleError)
}

export async function deletePaymentMethodApi(id) {
  const url = `${BASE_URL}/payment/payment-method/${id}`
  return await api.delete(url).catch(handleError)
}

export async function getListPaymentApi(payload) {
  const url = `${BASE_URL}/payment`
  return await api.get(url, payload).catch(handleError)
}

export async function getListHousesApi(payload) {
  const url = `${BASE_URL}/room-sample`
  return await api.get(url, payload).catch(handleError)
}

export async function deleteHouseApi(id) {
  const url = `${BASE_URL}/room-sample/${id}`
  return await api.delete(url).catch(handleError)
}

export async function getOneHouseApi(id) {
  const url = `${BASE_URL}/room-sample/${id}`
  return await api.get(url).catch(handleError)
}

export async function getPaymentApi(id) {
  const url = `${BASE_URL}/payment/${id}`
  return await api.getOrFail(url).catch(handleError)
}

export async function requestCancelPaymentApi(id) {
  const url = `${BASE_URL}/payment/request-cancel/${id}`
  return await api.post(url).catch(handleError)
}

export async function updateLicensesApi(payload) {
  const url = `${BASE_URL}/plans/licenses`
  return await api.put(url, payload).catch(handleError)
}

export async function getPaymentLicensesDetailApi(licenses) {
  const url = `${BASE_URL}/payment/licenses/detail/${licenses}`
  return await api.get(url).catch(handleError)
}

export async function changeLicensesApi(payload) {
  const url = `${BASE_URL}/payment/licenses/change`
  return await api.post(url, payload).catch(handleError)
}

export async function retryPaymentApi(payload) {
  const url = `${BASE_URL}/payment/invoice/repay`
  return await api.post(url, payload).catch(handleError)
}

export async function createServiceApi(payload) {
  const url = `${BASE_URL}/services`
  return await api.post(url, payload).catch(handleError)
}

export async function getServicesApi(payload) {
  const url = `${BASE_URL}/services`
  return await api.get(url, payload).catch(handleError)
}

export async function deleteServiceApi(id) {
  const url = `${BASE_URL}/services/${id}`
  return await api.delete(url).catch(handleError)
}

export async function exportPrChannelApi(id) {
  const url = `${BASE_URL}/export/pr-channels/${id}`
  return await api.get(url).catch(handleError)
}

export async function getServiceApi(id) {
  const url = `${BASE_URL}/services/${id}`
  return await api.getOrFail(url).catch(handleError)
}

export async function updateServiceApi(id, payload) {
  const url = `${BASE_URL}/services/${id}`
  return await api.put(url, payload).catch(handleError)
}

export async function getCapacityOrganizationApi() {
  const url = `${BASE_URL}/capacity/organization`
  return await api.get(url).catch(handleError)
}

export async function getLanguageApi(payload) {
  const url = `${BASE_URL}/language`
  return await api.get(url, payload).catch(handleError)
}

export async function changePasswordSuccessApi(id) {
  const url = `${BASE_URL}/password/change/success/${id}`
  return await api.post(url).catch(handleError)
}

export async function logLoginHistoryApi() {
  const url = `${BASE_URL}/log/login`
  return await api.post(url).catch(handleError)
}

export async function logLogoutHistoryApi() {
  const url = `${BASE_URL}/log/logout`
  return await api.post(url).catch(handleError)
}

export async function logAddPaymentMethodApi() {
  const url = `${BASE_URL}/log/add-payment-method`
  return await api.post(url).catch(handleError)
}

export async function downloadImageApi(id) {
  const url = `${BASE_URL}/${id}/download`
  return await api.get(url, {
    responseType: 'arraybuffer'
  }).catch(handleError)
}

export async function updateLanguage(payload) {
  const url = `${BASE_URL}/organization/language/update`
  return await api.put(url, payload).catch(handleError)
}
