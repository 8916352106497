import Vue from "vue";
import Router from "vue-router";
import VueRouteMiddleware from "vue-route-middleware";
import { auth, checkFunction, guest } from "src/router/middleware";
import i18n from "src/services/i18n";
import { PLAN_FUNCTION } from "src/helpers/constant";

Vue.use(Router);

const routes = [
  {
    path: "*",
    name: "Error404",
    component: () => import("src/errors/error_404"),
    meta: {
      title: i18n.t("side_bar.error_404"),
    },
  },
  //#region Require auth
  {
    path: "/",
    component: () => import("src/components/body"),
    meta: { requiresAuth: true },
    children: [
      //#region Home
      {
        path: "",
        name: "Home",
        component: () => import("src/views/admin/Home"),
        meta: {
          title: i18n.t("side_bar.home"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region Password change
      {
        path: "/password/change",
        name: "PasswordChange",
        component: () => import("src/views/auth/PasswordChange"),
        meta: {
          title: "Password Change",
          middleware: [auth],
        },
      },
      //#endregion

      //#region Admins
      // {
      //   path: "/admins",
      //   name: "AdminList",
      //   component: () => import("src/views/admin/admin/AdminList"),
      //   meta: {
      //     title: i18n.t("page_admin_list.page_title"),
      //     middleware: [auth],
      //   },
      // },
      //#endregion

      //#region Users
      {
        path: "/users",
        name: "UserList",
        component: () => import("src/views/admin/user/UserList"),
        meta: {
          title: i18n.t("side_bar.user_list"),
          middleware: [auth],
        },
      },
      {
        path: "/users/:id",
        name: "UserDetail",
        component: () => import("src/views/admin/user/UserDetail"),
        meta: {
          title: i18n.t("side_bar.user_detail"),
          middleware: [auth],
        },
      },
      {
        path: "/users/:id/edit",
        name: "UserEdit",
        component: () => import("src/views/admin/user/UserEdit"),
        meta: {
          title: i18n.t("side_bar.user_edit"),
          middleware: [auth],
        },
      },
      {
        path: "/users/:id/contact",
        name: "ContactList",
        component: () => import("src/views/admin/user/ContactList"),
        meta: {
          title: i18n.t("side_bar.contact_list"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region Groups
      {
        path: "/groups",
        name: "GroupList",
        component: () => import("src/views/admin/group/GroupList"),
        meta: {
          title: i18n.t("side_bar.group_list"),
          middleware: [auth],
        },
      },
      {
        path: "/groups/add",
        name: "GroupAdd",
        component: () => import("src/views/admin/group/GroupAdd"),
        meta: {
          title: i18n.t("side_bar.group_add"),
          middleware: [auth],
        },
        props: { isEdit: false },
      },
      {
        path: "/groups/:id",
        name: "GroupDetail",
        component: () => import("src/views/admin/group/GroupDetail"),
        meta: {
          title: i18n.t("side_bar.group_detail"),
          middleware: [auth],
        },
      },
      {
        path: "/groups/:id/edit",
        name: "GroupEdit",
        component: () => import("src/views/admin/group/GroupEdit"),
        meta: {
          title: i18n.t("side_bar.group_edit"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region Plans
      {
        path: "/plans",
        name: "PlanDetail",
        component: () => import("src/views/admin/plan/PlanDetail"),
        meta: {
          title: i18n.t("side_bar.plan_detail"),
          middleware: [auth],
        },
      },
      {
        path: "/plans/change",
        name: "PlanChange",
        component: () => import("src/views/admin/plan/PlanChange"),
        props: true,
        meta: {
          title: i18n.t("side_bar.plan_change"),
          middleware: [auth],
        },
      },
      {
        path: "/plans/stop",
        name: "RequestStopService",
        component: () => import("src/views/admin/plan/RequestStopService"),
        meta: {
          title: i18n.t("side_bar.request_stop_service"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region Licenses
      {
        path: "/licenses/change",
        name: "LicenseChange",
        component: () => import("src/views/admin/plan/LicenseChange"),
        meta: {
          title: i18n.t("side_bar.license_change"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region Storages
      {
        path: "/storages",
        name: "StorageList",
        component: () => import("src/views/admin/plan/StorageList"),
        meta: {
          title: i18n.t("side_bar.storage_list"),
          middleware: [auth],
        },
      },
      {
        path: "/storages/:id/confirm",
        name: "StorageConfirm",
        component: () => import("src/views/admin/plan/StorageConfirm"),
        props: true,
        meta: {
          title: i18n.t("side_bar.storage_confirm"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region Subscription
      {
        path: "/subscription/create/:id",
        name: "SubscriptionCreate",
        component: () =>
          import("@/views/admin/subscription/SubscriptionCreate"),
        meta: {
          title: i18n.t("page_subscription_create.page_title"),
          middleware: [auth],
        },
      },

      //#endregion

      //#region Payments
      {
        path: "/payments",
        name: "PaymentHistory",
        component: () => import("src/views/admin/payment/PaymentHistory"),
        meta: {
          title: i18n.t("side_bar.payment_history"),
          middleware: [auth],
        },
      },
      {
        path: "/payment/:id",
        name: "PaymentDetail",
        component: () => import("src/views/admin/payment/PaymentDetail"),
        meta: {
          title: i18n.t("side_bar.payment_detail"),
          middleware: [auth],
        },
      },
      {
        path: "/payment/retry/:id",
        name: "RetryPayment",
        component: () => import("src/views/admin/payment/RetryPayment"),
        meta: {
          title: i18n.t("side_bar.retry_payment"),
          middleware: [auth],
        },
      },
      {
        path: "/card/add",
        name: "PaymentAddCard",
        component: () => import("src/views/admin/payment/PaymentAddCard"),
        meta: {
          title: i18n.t("side_bar.add_card"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region Security
      {
        path: "/security",
        name: "Security",
        component: () => import("src/views/admin/security/Security"),
        meta: {
          title: i18n.t("side_bar.security"),
          functions: [
            PLAN_FUNCTION.FILE_TRANSMISSION,
            PLAN_FUNCTION.IP_DEVICE_RESTRICTIONS,
          ],
          middleware: [auth, checkFunction],
        },
      },
      //#endregion

      //#region Rooms
      {
        path: "/rooms",
        name: "RoomList",
        component: () => import("src/views/admin/rooms/RoomList"),
        meta: {
          title: i18n.t("side_bar.rooms"),
          middleware: [auth],
        },
      },
      {
        path: "/rooms/add",
        name: "RoomAdd",
        component: () => import("src/views/admin/rooms/RoomAdd"),
        meta: {
          title: i18n.t("page_room_add.page_title"),
          middleware: [auth],
        },
      },
      {
        path: "/rooms/edit/:id",
        name: "RoomEdit",
        component: () => import("src/views/admin/rooms/RoomEdit"),
        meta: {
          title: i18n.t("page_room_edit.page_title"),
          middleware: [auth],
        },
      },
      {
        path: "/rooms/entry-history/:id",
        name: "RoomHistory",
        component: () => import("src/views/admin/rooms/RoomHistory"),
        meta: {
          title: i18n.t("side_bar.history"),
          middleware: [auth],
        },
      },
      {
        path: "/rooms/statistics",
        name: "RoomStatistics",
        component: () => import("src/views/admin/rooms/RoomStatistics"),
        meta: {
          title: i18n.t("side_bar.statistics"),
          middleware: [auth],
        },
      },
      {
        path: "/statistics/:id",
        name: "StatisticsDetail",
        component: () => import("src/views/admin/rooms/StatisticsDetail"),
        meta: {
          title: i18n.t("side_bar.statistics"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region Room Items
      {
        path: "/room-items",
        name: "RoomItems",
        component: () => import("src/views/admin/room-items/RoomItemList"),
        meta: {
          title: i18n.t("page_room_items.page_title"),
          middleware: [auth],
        },
      },
      {
        path: "/room-items/shopping",
        name: "RoomItemBuy",
        component: () => import("src/views/admin/room-items/RoomItemBuy"),
        meta: {
          title: i18n.t("page_room_items_buy.page_title"),
          middleware: [auth],
        },
      },
      {
        path: "/room-items/detail/:id",
        name: "RoomItemDetail",
        component: () => import("src/views/admin/room-items/RoomItemDetail"),
        meta: {
          title: i18n.t("page_room_items_detail.page_title"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region Profile
      {
        path: "/profile",
        name: "ProfileDetail",
        component: () => import("src/views/admin/profile/ProfileDetail"),
        meta: {
          title: i18n.t("side_bar.profile_detail"),
          middleware: [auth],
        },
      },
      {
        path: "/profile/edit",
        name: "ProfileEdit",
        component: () => import("src/views/admin/profile/ProfileEdit"),
        meta: {
          title: i18n.t("side_bar.edit_profile"),
          middleware: [auth],
        },
      },
      {
        path: "/owner/edit",
        name: "OwnerEdit",
        component: () => import("src/views/admin/profile/OwnerEdit"),
        meta: {
          title: i18n.t("owner_edit"),
          middleware: [auth],
        },
      },
      //#endregion

      //#region PR Channel
      {
        path: "/pr-channels",
        name: "PRChannelList",
        component: () => import("src/views/admin/prchannel/PRChannelList"),
        meta: {
          title: i18n.t("pr_channel_list"),
          functions: [PLAN_FUNCTION.PR_CHANNEL],
          middleware: [auth, checkFunction],
        },
      },
      {
        path: "/pr-channels/add",
        name: "PRChannelAdd",
        component: () => import("src/views/admin/prchannel/PRChannelAdd"),
        meta: {
          title: i18n.t("create_pr_channel"),
          functions: [PLAN_FUNCTION.PR_CHANNEL],
          middleware: [auth, checkFunction],
        },
      },
      {
        path: "/pr-channels/:id/edit",
        name: "PRChannelEdit",
        component: () => import("src/views/admin/prchannel/PRChannelEdit"),
        meta: {
          title: i18n.t("create_pr_channel"),
          functions: [PLAN_FUNCTION.PR_CHANNEL],
          middleware: [auth, checkFunction],
        },
      },
      //#endregion

      //#region Channel
      {
        path: "/channel",
        name: "ChannelSetting",
        component: () => import("src/views/admin/channel/ChannelSetting"),
      },
      //#endregion

      //#region Operator management
      {
        path: "/operator-group",
        name: "OperatorGroupList",
        component: () =>
          import("src/views/admin/operator-management/GroupList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/operator-group/create",
        name: "OperatorGroupAdd",
        component: () => import("src/views/admin/operator-management/GroupAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/operator-group/edit/:id",
        name: "OperatorGroupEdit",
        component: () =>
          import("src/views/admin/operator-management/GroupEdit"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/operator",
        name: "OperatorList",
        component: () =>
          import("src/views/admin/operator-management/OperatorList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/invite-operator",
        name: "InviteOperatorList",
        component: () =>
          import("src/views/admin/operator-management/InviteOperatorList"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/invite-operator/create/:id",
        name: "InviteOperatorAdd",
        component: () =>
          import("src/views/admin/operator-management/InviteOperatorAdd"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/invite-operator/edit/:id",
        name: "InviteOperatorEdit",
        component: () =>
          import("src/views/admin/operator-management/InviteOperatorEdit"),
        meta: {
          middleware: [auth],
        },
      },
      //#endregion

      {
        path: "/cart",
        name: "CartDetail",
        component: () => import("src/views/admin/shopping-cart/Cart"),
      },

      {
        path: "/cart/checkout",
        name: "CartCheckout",
        component: () => import("src/views/admin/shopping-cart/CartCheckout"),
      },

      {
        path: "/purchase-success",
        name: "PurchaseSuccess",
        component: () =>
          import("src/views/admin/shopping-cart/PurchaseSuccess"),
      },
    ],
  },
  //#endregion
  //#region No require auth
  {
    path: "/",
    component: () => import("src/views/auth/Auth"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("src/views/auth/Login"),
        meta: {
          title: i18n.t("side_bar.login"),
          middleware: [guest],
        },
      },
      {
        path: "login/verify",
        name: "LoginVerify",
        component: () => import("src/views/auth/LoginVerify"),
        props: true,
        meta: {
          title: i18n.t("side_bar.login_verify"),
          middleware: [guest],
        },
      },
      {
        path: "/password/reset",
        name: "PasswordReset",
        component: () => import("src/views/auth/PasswordReset"),
        meta: {
          title: i18n.t("side_bar.password_reset"),
          middleware: [guest],
        },
      },
      {
        path: "/password/complete",
        name: "PasswordComplete",
        component: () => import("src/views/auth/PasswordComplete"),
        props: true,
        meta: {
          title: i18n.t("side_bar.password_complete"),
          middleware: [guest],
        },
      },
      {
        path: "/company/register",
        name: "RegisterCompany",
        component: () => import("src/views/auth/RegisterCompany"),
        meta: {
          title: i18n.t("side_bar.register_company"),
          middleware: [guest],
        },
      },
    ],
  },
  //#endregion
];

const router = new Router({
  routes,
  base: "/",
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(VueRouteMiddleware());

export default router;
